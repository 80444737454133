<template>
  <page-content>
    <a-tabs v-model="tabKey" tab-position="left" style="width: 200px;position: fixed">
      <a-tab-pane key="1" tab="商品信息"></a-tab-pane>
      <a-tab-pane key="2" tab="规格库存售价"></a-tab-pane>
      <a-tab-pane key="3" tab="提成设置"> </a-tab-pane>

      <div style="text-align: right;padding:20px" slot="tabBarExtraContent">
        <a-button type="primary" @click="clickSave" :loading="saveLoading">保存</a-button>
      </div>

    </a-tabs>

    <div class="tab-content" style="margin-left: 200px">
      <a-form-model
              v-show="tabKey=='1'"
              ref="ruleForm"
              v-bind="formItemLayout"
              layout="horizontal"
              :rules="rules"
              :model="model">

        <a-form-model-item label='商品图片' prop="picture">
          <image-upload v-model="model.picture"/>
        </a-form-model-item>

        <a-form-model-item label='轮播图' prop="banners">
          <multi-image-upload ref="bannersUpload" v-model="model.banners"></multi-image-upload>
        </a-form-model-item>

        <a-form-model-item label='商品名称' prop="name">
          <a-input v-model="model.name"/>
        </a-form-model-item>

        <a-form-model-item label='商品介绍' prop="des">
          <a-textarea v-model="model.des"/>
        </a-form-model-item>

        <a-form-model-item label='商品编号' prop="name">
          <a-input v-model="model.sn"/>
        </a-form-model-item>

        <a-form-model-item label='商品详情' prop="detail">
          <rich-text-field v-model="model.detail"></rich-text-field>
        </a-form-model-item>

        <a-form-model-item label='详情图' prop="res">
          <multi-image-upload ref="resUpload" v-model="model.res"></multi-image-upload>
        </a-form-model-item>

      </a-form-model>

      <goods-specs v-show="tabKey=='2'" ref="goodsSpecs"></goods-specs>

      <a-form-model
              v-show="tabKey=='3'"
              ref="ruleForm"
              v-bind="formItemLayout"
              layout="horizontal"
              :rules="rules"
              :model="model">


        <a-form-model-item label='一级提成' prop="name">
          <a-input v-model="model.commission1"/>
        </a-form-model-item>

        <a-form-model-item label='二级提成' prop="name">
          <a-input v-model="model.commission2"/>
        </a-form-model-item>

        <!--          <a-form-model-item label='操作'>-->
        <!--            <a-button type="primary">保存</a-button>-->
        <!--          </a-form-model-item>-->

      </a-form-model>
    </div>

  </page-content>
</template>

<script>
  import GoodsSpecs from "../_components/goods-specs"
  export default {
    components:{GoodsSpecs},
    data() {
      return {
        model: {},
        formItemLayout: {
          labelCol: {span: 5},
          wrapperCol: {span: 17},
        },
        rules: {},
        tabKey:"1",
        saveLoading:false

      }
    },
    created() {
      let spuId = this.$route.query.id
      if(spuId){
        this.$get(`web/mall/goods/${spuId}`).then(suc=>{
          this.model = suc.data
          this.skuList = suc.data.skuList

          setTimeout(()=>{
            let specs = JSON.parse(this.model.specs||"[]")
            let skuList = suc.data.skuList
            this.$refs.goodsSpecs.setData(specs,skuList)
          },500)


          if(this.model.res){
            this.model.res = JSON.parse(this.model.res)
            this.$refs.resUpload.setValue(this.model.res)
          }

          if(this.model.banners){
            this.model.banners = JSON.parse(this.model.banners)
            this.$refs.bannersUpload.setValue(this.model.banners)
          }

        })
      }

    },
    methods: {
      clickSave() {
        this.saveLoading = true
        let data = {...this.model}
        let {specs,skuList} = this.$refs.goodsSpecs.getData()
        data.specs = JSON.stringify(specs)
        data.skuList = skuList
        data.res = JSON.stringify(data.res)
        data.banners = JSON.stringify(data.banners)
        this.$postJson("/web/mall/goods",data).then(suc=>{
          this.saveLoading=false
          this.$router.replace("/mall/goods")
        }).catch(err=>{
          this.saveLoading=false
        })
      },

    }
  }
</script>

<style scoped>
.tab-content{
  height: 100%;
}

</style>
